import React, { useState } from 'react'
import { m } from 'framer-motion'
import cx from 'classnames'
import ProductHero from '@modules/shop/product-hero'

import { hasObject } from '@lib/helpers'

const itemAnim = {
  initial: {
    opacity: 0,
  },
  show: (i) => ({
    opacity: 1,
    transition: {
      delay: i * 0.05 + 0.4,
      duration: 0.3,
      ease: 'linear',
    },
  }),
  hide: (i) => ({
    opacity: 0,
    transition: {
      delay: i * 0.05,
      duration: 0.3,
      ease: 'linear',
    },
  }),
}

const ProductHeroCard = ({ product, index, className, showSummary }) => {
  if (!product) return null

  return (
    <m.div
      initial="initial"
      animate="show"
      exit="hide"
      custom={index}
      variants={itemAnim}
      className={cx('product-details-card', className)}
    >
      <ProductHero product={product} showSummary={showSummary} />
    </m.div>
  )
}

export default ProductHeroCard
